import {BarSeriesType} from '@mui/x-charts';
import {AccountReport, Expense, FormData, ServiceReport} from '../../model/BillingReporting';

export function getAPIFormattedDateFromExpense(expense: Expense): Date {
  let timestamp: string = '';
  if (expense.day) {
    timestamp = expense.day;
  } else if (expense.month) {
    timestamp = expense.month;
  } else if (expense.year) {
    timestamp = expense.year;
  }

  if (!timestamp) {
    throw Error('expense date not supported');
  }

  const timestampSegments = (timestamp as string).split('-').map((segment, index) => {
    if (index === 1) {
      return Number(segment) - 1;
    }
    return Number(segment);
  });

  if (!timestampSegments.every((segment) => segment)) throw Error('api timestamp is malformatted');

  let date: Date;
  if (timestampSegments.length === 1) {
    date = new Date(timestampSegments[0], 0);
  } else if (timestampSegments.length === 2) {
    date = new Date(timestampSegments[0], timestampSegments[1]);
  } else {
    date = new Date(timestampSegments[0], timestampSegments[1], timestampSegments[2]);
  }
  return date;
}

export function formatForBarChart(reportData: AccountReport, formData: FormData): Omit<BarSeriesType, 'type'>[] {
  function getDataToMatchingDates(expenses: Expense[]): number[] {
    const formattedData: number[] = [];
    if (!formData.range?.[0] || !formData.range?.[1]) {
      return formattedData;
    }
    const allDates = getXAxisDates(formData.range[0], formData.range[1], formData.groupBy);
    expenses.forEach((expense) => {
      if (!expense.day && !expense.month && !expense.year) return;
      const date = getAPIFormattedDateFromExpense(expense);
      const dateIndex = allDates.findIndex((Xdate) => {
        if (formData.groupBy === 'years') {
          return Xdate.getFullYear() === date.getFullYear();
        } else if (formData.groupBy === 'months') {
          return Xdate.getFullYear() === date.getFullYear() && Xdate.getMonth() === date.getMonth();
        } else {
          return Xdate?.toDateString() === date?.toDateString();
        }
      });
      if (dateIndex === -1) {
        throw Error('date not found');
      }
      const formattedDataEntries = formattedData.length;
      if (dateIndex === formattedDataEntries) {
        formattedData.push(Number(expense.spent));
      } else {
        formattedData.push(...Array(Math.abs(dateIndex - formattedDataEntries)).fill(0));
        formattedData.push(Number(expense.spent));
      }
    });

    const xAxisLen = getFormattedXAxisDates(formData)?.length;
    if (xAxisLen && xAxisLen > formattedData.length) {
      formattedData.push(...Array(Math.abs(xAxisLen - formattedData.length)).fill(0));
    }

    return formattedData;
  }
  const formattedData = [];
  for (const service in reportData) {
    const serviceData = reportData[service] as ServiceReport;
    const formattedServiceData = {
      data: getDataToMatchingDates(serviceData.expenses),
      label: service,
      curve: 'natural',
    };
    formattedData.push(formattedServiceData);
  }
  return formattedData;
}

export function getXAxisDates(startDate: Date, endDate: Date, groupBy: string): Date[] {
  const allDates = [];
  const currentDate = new Date(startDate);
  let dateToCompare = endDate;
  if (groupBy === 'months') {
    dateToCompare = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
  }

  while (currentDate <= dateToCompare) {
    allDates.push(new Date(currentDate));
    if (groupBy === 'days') {
      currentDate.setDate(currentDate.getDate() + 1);
    } else if (groupBy === 'months') {
      currentDate.setMonth(currentDate.getMonth() + 1);
    } else if (groupBy === 'years') {
      currentDate.setMonth(currentDate.getFullYear() + 1);
    }
  }
  return allDates;
}

export function getFormattedXAxisDates(formData: FormData): string[] | undefined {
  if (!formData.range?.[0] || !formData.range?.[1]) {
    return;
  }

  const allDates = getXAxisDates(formData.range[0], formData.range[1], formData.groupBy);
  return allDates.map((date) => {
    const [year, month, day] = [
      String(date.getFullYear()),
      String(date.getMonth() + 1).padStart(2, '0'),
      String(date.getDate()).padStart(2, '0'),
    ];
    return formData.groupBy === 'days' ? `${month}/${day}` : `${month}/${year}`;
  });
}
