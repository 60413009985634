import {CSSObject, CSSProperties} from '@emotion/serialize';
import {useTheme} from '@mui/material';
import {CSSObjectWithLabel, GroupBase, StylesConfig} from 'react-select';
import {paletteModeDark, useColors} from '../../../../context/LightDarkThemeProvider';
import {SpartanFieldOption} from '../../model';

export function GetStyleForSortableMultiSelect(): StylesConfig<SpartanFieldOption, true> {
  const theme = useTheme();
  const colors = useColors();
  const fontWeightDragAndDrop = 'lighter';
  const fontFamilyDragAndDrop = 'Quicksand';
  const fontSizeDragAndDrop = '0.9285714285714285rem';

  const customStyleForSortableMultiSelect: StylesConfig<SpartanFieldOption, true> = {
    option: (provided: any, state) => ({
      ...provided,
      fontWeight: fontWeightDragAndDrop,
      margin: 1,
      fontFamily: fontFamilyDragAndDrop,
      fontSize: fontSizeDragAndDrop,

      background:
        theme.palette.mode === paletteModeDark
          ? state.isFocused
            ? colors.dragAndDropColors.darkGreyOpacityColor
            : theme.palette.background.default
          : state.isFocused
          ? colors.dragAndDropColors.lightGreyOpacityColor
          : theme.palette.primary.contrastText,
      '&:hover': {
        background:
          theme.palette.mode === paletteModeDark
            ? colors.dragAndDropColors.darkGreyOpacityColor
            : colors.dragAndDropColors.lightGreyOpacityColor,
      },
    }),
    input: (provided: any) => ({
      ...provided,
      fontWeight: fontWeightDragAndDrop,
      fontFamily: fontFamilyDragAndDrop,
      fontSize: fontSizeDragAndDrop,
      color:
        theme.palette.mode === paletteModeDark ? theme.palette.secondary.main : theme.palette.secondary.contrastText,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: fontWeightDragAndDrop,
      fontFamily: fontFamilyDragAndDrop,
      fontSize: fontSizeDragAndDrop,
    }),
    control: (provided: any) => ({
      ...provided,
      background:
        theme.palette.mode === paletteModeDark ? theme.palette.background.default : theme.palette.primary.contrastText,
      border:
        theme.palette.mode === paletteModeDark
          ? `1px solid ${colors.dragAndDropColors.darkGreyColor}`
          : `1px solid ${colors.dragAndDropColors.lightGreyColor}`,
      '&:hover': {
        borderColor:
          theme.palette.mode === paletteModeDark
            ? colors.dragAndDropColors.lightGreyColor
            : theme.palette.secondary.contrastText,
      },
      borderRadius: '3px',
    }),
    menuPortal: (base: any) => ({...base, zIndex: 9999}),
    menuList: (provided: any, state) => ({
      ...provided,
      color:
        theme.palette.mode === paletteModeDark ? theme.palette.secondary.main : theme.palette.secondary.contrastText,
      background:
        theme.palette.mode === paletteModeDark ? theme.palette.background.default : theme.palette.primary.contrastText,
    }),
  };
  return customStyleForSortableMultiSelect;
}
