import {RefreshOutlined} from '@mui/icons-material';
import {Box, Divider, IconButton, Stack, Typography, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';
import {Link} from '@mui/material';
import {useAxiosContext} from '../context/AxiosContext';
import UserService from '../services/UserService';
import {BillingAccount} from '../model/BillingReporting';

export interface AccountBalance {
  balance: string;
  name: string;
  transactions_from: string;
  transactions_to: string;
}

export default function SideNavBalance() {
  const theme = useTheme();
  const {t} = useTranslation();
  const {useAxiosAccountsAPI} = useAxiosContext();
  const [accounts, setAccounts] = useState<BillingAccount[]>();

  const [isRefreshDisabled, setIsRefreshDisabled] = useState<boolean>(true);
  const [{data: entityData, error: getEntityError}, getEntityData] = useAxiosAccountsAPI<AccountBalance>(
    {
      url: `/reporting/accounts/${accounts?.[0].account_id}/balance`,
      method: 'GET',
    },
    {manual: true}
  );

  useEffect(() => {
    const subscription = UserService.getInstance()
      .get()
      .subscribe((user) => {
        if (user?.accounts) {
          setAccounts(user.accounts);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (accounts) {
      setIsRefreshDisabled(false);
      getEntityData();
      const interval = setInterval(getEntityData, 1000 * 60 * 5);
      return () => clearInterval(interval);
    }
  }, [accounts]);

  return accounts ? (
    <Box padding={'0 30px'} position="sticky" top={0} zIndex={1} sx={{backgroundColor: theme.palette.background.paper}}>
      <Stack direction={'row'} justifyContent="space-between" paddingTop={'10px'} marginBottom={1}>
        <Typography fontSize={18} variant={'h3'} alignSelf={'center'} margin={0}>
          {t('side-nav.account-balance')}
        </Typography>
      </Stack>
      {accounts.length > 1 ? (
        <Typography sx={{textWrap: 'wrap'}} fontSize={14}>
          {t('billing.multiple-accounts-detected')}{' '}
          <Link component={RouterLink} to="/billing-reporting">
            {t('billing.go-to-billing-page')}
          </Link>
        </Typography>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Box paddingTop={'8px'}>
              <Typography fontSize={20}>${entityData && entityData.balance}</Typography>
            </Box>
            <IconButton
              sx={{
                alignSelf: 'end',
              }}
              onClick={() => {
                accounts && getEntityData();
                setIsRefreshDisabled(true);
                setTimeout(() => setIsRefreshDisabled(false), 1000);
              }}
              disabled={isRefreshDisabled}
            >
              <RefreshOutlined color={isRefreshDisabled ? 'secondary' : 'primary'} fontSize={'medium'} />
            </IconButton>
          </Stack>
        </>
      )}
      <Divider sx={{marginTop: '10px', marginBottom: '10px'}} />
    </Box>
  ) : null;
}
