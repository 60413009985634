import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Box, Button, Container, Grid, IconButton, Menu, MenuItem} from '@mui/material';
import {Skeleton} from '@mui/lab';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName, Path} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {formatDateTime} from '../../utils/DateUtils';
import {useTableColumns} from '../UseTableColumns';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import {TicketConfig, TicketConfigListItem} from '../../model/Event';

function TicketConfigListPage() {
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const {useAxiosOutletOrderAPI} = useAxiosContext();
  const [tickets, setTickets] = useState<TicketConfig[]>();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.TICKETS_CONFIG);

  const [{data: entityList, loading: isEntityListLoading, error: hasEntityListError}] = useAxiosOutletOrderAPI<
    ResponseListWrapper<TicketConfigListItem>
  >(
    {
      url: `/outlet-item-configs`,
      params: {
        formatted: true,
      },
    },
    {
      manual: false,
    }
  );

  const tableColumns = useTableColumns({
    featureName: FeatureName.TICKETS_CONFIG,
    defaultActionColumn: true,
  });

  useEffect(() => {
    if (entityList) {
      setTickets(entityList.results);
    }
  }, [entityList]);

  useEffect(() => {
    setLoading(isEntityListLoading, 'TicketListPage');
  }, [isEntityListLoading]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasEntityListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('tickets-config.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={`/${Path.TICKETS_CONFIG}/create`}>
                      <Button fullWidth color="secondary" id={'add-btn'} {...{[TestAttributes.BUTTON_NAME]: 'add-btn'}}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12} sx={{height: '500px'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  tickets?.map((entity: TicketConfigListItem, index: number) => {
                    return {
                      ...entity,
                      created_at: entity.created_at && formatDateTime(entity.created_at),
                      last_updated_at: entity.last_updated_at && formatDateTime(entity.last_updated_at),
                      actions: entity.outlet_item_config_id,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default TicketConfigListPage;
