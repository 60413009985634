export enum FeatureName {
  LEADS = 'leads',
  TEAMS = 'teams',
  DEMO = 'demo',
  USERS = 'users',
  TASKS = 'tasks',
  SMS = 'sms',
  LEAD_SUMMARY = 'lead_summary',
  LEADS_QUERIES = 'leads_queries',
  MASSIVE_UPDATE = 'massive_update',
  CALL = 'call',
  EMAIL = 'email',
  COMMENTS = 'comments',
  CAMPAIGNS = 'campaigns',
  DASHBOARD = 'dashboard',
  QUERIES = 'queries',
  PROFILE = 'profile',
  SETTINGS = 'settings',
  CMP_SETTINGS = 'cmpsettings',
  GENERAL_SETTINGS = 'general_settings',
  NIGHT_SETTINGS = 'nightsettings',
  SETTINGS_CHAT = 'chat_settings',
  SETTINGS_NOTIFICATIONS = 'notifications',
  SIDENAV = 'sidenav',
  SMS_LOG = 'sms_log',
  SMS_PORTAL = 'sms portal',
  SMS_PORTAL_API_WORKAROUND_PATH = 'sms-portal',
  IMPORTS = 'imports',
  FILES = 'files',
  METAFILES = 'metafiles',
  NAVBAR = 'navbar',
  RECORDING_ANALYTICS = 'recording_analytics',
  RECORDING_ANALYTICS_QUERIES = 'recording_analytics_queries',
  USER_GUIDE = 'user-guides',
  AUTO_ACTIONS = 'automatic_actions',
  CDR = 'cdr',
  CDR_QUERIES = 'cdr_queries',
  FEATURE_FIELDS = 'featurefields',
  REPORTING_ANALYTICS = 'reporting-analytics',
  CREATIVE_ASSISTANT = 'creative-assistant',
  ORGANIZATION = 'organization',
  FEATURES = 'features_administration',
  SECTIONS = 'sections',
  OPERATIONS = 'operations',
  LOCAL_PRESENCE_DIDS = 'local-presence-dids',
  PAYMENT_CLEARANCE = 'payment-clearance',
  RVM_LOG = 'rvm-log',
  RVM_LOG_QUERIES = 'rvm-log_queries',
  AI_CALL_LOG = 'ai-call-log',
  AI_CALL_LOG_QUERIES = 'ai-call-log_queries',
  AI_AGENT_TRIGGERS = 'ai-agent-triggers',
  RVM_CAMPAIGNS = 'rvm-campaigns',
  AI_CALL_CAMPAIGNS = 'ai-call-campaigns',
  BILLING_ACCOUNTS = 'billing-accounts',
  BILLING_REPORTING = 'billing-reporting',
  CALLER_ID_CONFIGS = 'caller-id-configs',
  EVENTS = 'events',
  TICKETS_CONFIG = 'tickets-config',
  TICKETS = 'tickets',
}

export enum Path {
  DASHBOARD = 'dashboard',
  LOGIN = 'login',
  FEATURE = 'feature',
  SMS_PORTAL = 'sms/portal',
  MASSIVE_UPDATE = 'update',
  CDR = 'cdr',
  USER_GUIDE = 'user-guides',
  CREATIVE_ASSISTANT = 'spartamax/creative-assistant',
  FEATURES = 'features',
  CONDITIONAL_RULES = 'conditional_rules',
  LOCAL_PRESENCE_DIDS = 'local-presence-dids',
  SMS_LOG = 'sms-log',
  RECORDING_ANALYTICS = 'recording-analytics',
  SYS_SETTINGS_ROOT = 'system-settings',
  SYS_SETTINGS_ROLE_PERMISSIONS = 'role-permissions',
  SYS_SETTINGS_USER_PERMISSIONS = 'user-permissions',
  SYS_SETTINGS_CACHE = 'cache',
  SYS_SETTINGS_FORM_EDITOR = 'form-editor',
  SYS_SETTINGS_FRONTEND_CONFIG = 'frontend-config',
  REPLACE_LOCAL_PRESENCE_DIDS = 'local-presence-dids/batch-upload-replace',
  PAYMENT_CLEARANCE = 'payment-clearance',
  AI_AGENT_TRIGGERS = 'ai-agent-triggers',
  BILLING_REPORTING = 'billing-reporting',
  BILLING_ACCOUNTS = 'billing-accounts',
  CALLER_ID_CONFIGS = 'caller-id-configs',
  EVENTS = 'events',
  EVENT_ORDERS = 'event-orders',
  TICKETS_CONFIG = 'tickets-config',
  TICKETS = 'tickets',
}
