import {useEffect, useState} from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {Box, Button, Container, Grid, IconButton, Menu, MenuItem} from '@mui/material';
import {Skeleton} from '@mui/lab';
import PageHeadline from '../../components/PageHeadline';
import {PageTopActions} from '../PageTopActions';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {formatDateTime} from '../../utils/DateUtils';
import {useTableColumns} from '../UseTableColumns';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import {EventListItem} from '../../model/Event';
import {ColumnSizes} from '../../components/shared/ColumnSize';
import {GridCellParams, GridColDef, GridRowModel} from '@mui/x-data-grid-pro';

function EventListPage() {
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const {useAxiosOutletOrderAPI} = useAxiosContext();
  const [events, setEvents] = useState<EventListItem[]>();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.EVENTS);
  const [actionColumn, setActionColumn] = useState<GridColDef>();
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEvent, setSelectedEvent] = useState<EventListItem | null>(null);

  const navigate = useNavigate();
  const [{data: entityList, loading: isEntityListLoading, error: hasEntityListError}] = useAxiosOutletOrderAPI<
    ResponseListWrapper<EventListItem>
  >(
    {
      url: `/outlets`,
      params: {
        formatted: true,
        type: 'event',
      },
    },
    {
      manual: false,
    }
  );

  const tableColumns = useTableColumns({
    featureName: FeatureName.EVENTS,
    actionColumn,
  });

  function handleActionsMenuIconClick(event: React.MouseEvent<HTMLElement>, cell: GridCellParams): void {
    const {row}: GridRowModel = cell;
    setActionsMenuAnchorEl(event.currentTarget);
    setSelectedEvent(row);
  }
  useEffect(() => {
    setActionColumn({
      field: 'actions',
      headerName: t('table-columns.actions'),
      disableExport: true,
      filterable: false,
      width: ColumnSizes.MD,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <IconButton
              id="campaign-list-more-actions-btn"
              aria-haspopup="true"
              onClick={(event: React.MouseEvent<HTMLElement>) => handleActionsMenuIconClick(event, params)}
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
          </>
        );
      },
    });
  }, []);

  useEffect(() => {
    if (entityList) {
      setEvents(entityList.results);
    }
  }, [entityList]);

  useEffect(() => {
    setLoading(isEntityListLoading, 'EventListPage');
  }, [isEntityListLoading]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasEntityListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('events.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to="/events/create">
                      <Button fullWidth color="secondary" id={'add-btn'} {...{[TestAttributes.BUTTON_NAME]: 'add-btn'}}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12} sx={{height: '500px'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  events?.map((entity: EventListItem, index: number) => {
                    return {
                      ...entity,
                      created_at: entity.created_at && formatDateTime(entity.created_at),
                      last_updated_at: entity.last_updated_at && formatDateTime(entity.last_updated_at),
                      actions: entity.outlet_id,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
            {selectedEvent && (
              <Menu
                id="campaign-list-more-actions-menu"
                keepMounted
                anchorEl={actionsMenuAnchorEl}
                open={Boolean(actionsMenuAnchorEl)}
                onClose={() => setActionsMenuAnchorEl(null)}
              >
                <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW]}>
                  <MenuItem
                    id={'campaign-list-more-actions-menu-item-view'}
                    onClick={() => navigate(`/events/${selectedEvent.outlet_id}`)}
                  >
                    {t('shared.view')}
                  </MenuItem>
                </AccessControl>
                <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW]}>
                  <MenuItem
                    id={'campaign-list-more-actions-menu-item-view-event-orders'}
                    onClick={() => navigate(`/event-orders/${selectedEvent.outlet_id}`)}
                  >
                    {t('events.view-orders')}
                  </MenuItem>
                </AccessControl>
                <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW]}>
                  <MenuItem
                    id={'campaign-list-more-actions-menu-item-view-event-tickets'}
                    onClick={() => navigate(`/events/${selectedEvent.outlet_id}/tickets`)}
                  >
                    {t('events.view-tickets')}
                  </MenuItem>
                </AccessControl>
              </Menu>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default EventListPage;
