import {PropsWithChildren, ReactElement} from 'react';

export enum UserPermissions {
  VIEW = 'view',
  VIEW_UPLOAD_ATTACHMENTS = 'view_upload_attachments',
  VIEW_ALL_TASKS = 'view_all_tasks',
  VIEW_REQUEST_TINY_URL = 'view_request_tiny_url',
  VIEW_UPLOAD_CENTER = 'view_upload_center',
  VIEW_GENERATE_PDF = 'view_generate_pdf',
  VIEW_UPLOAD_TSV = 'view_upload_tsv',
  VIEW_DELETE_PAYMENT = 'view_delete_payment',
  VIEW_EXPORT = 'view_export',
  VIEW_MASSIVE_UPDATE = 'view_massive_update',
  VIEW_ALL_CHATS = 'view_all_chats',
  VIEW_CALL_NOW = 'view_call_now',
  VIEW_ALL_CAMPAIGNS = 'can_view_all_campaigns',
  VIEW_TRANSLATION = 'view_translation',
  VIEW_EMAIL_SUPPORT = 'view_email_support',
  VIEW_CHAT_SUPPORT = 'view_chat_support',
  VIEW_TALK_SUPPORT = 'view_talk_support',
  VIEW_PROFILE = 'view_profile',
  VIEW_CONFIG_SETTING = 'view_config_settings',
  VIEW_TABLE_COLUMNS_CONFIG = 'view_table_columns_config',
  VIEW_TABLE_EXPORT = 'view_table_export',
  VIEW_TABLE_ACTION_COLUMN = 'view_table_action_column',
  VIEW_TABLE_OVERVIEW_COLUMN = 'view_table_overview_column',
  VIEW_PUBLIC_QUERIES = 'view_public_queries',
  VIEW_LEAD_SUMMARY = 'view_lead_summary',
  VIEW_QUERIES = 'view_queries',
  VIEW_CLICK_TO_ACTIONS = 'click_to_actions',
  VIEW_CLICK_TO_COMMENT = 'view_click_to_comment',
  VIEW_CLICK_TO_EMAIL = 'view_click_to_email',
  VIEW_CLICK_TO_CALL = 'view_click_to_call',
  VIEW_CLICK_TO_SMS = 'view_click_to_sms',
  VIEW_CLICK_TO_TASK = 'view_click_to_task',
  VIEW_SETTINGS_PAYMENT = 'view_settings_payment',
  VIEW_EXPORT_PAYMENT_INFORMATION = 'view_export_payment_information',
  VIEW_WITHDRAW = 'view_withdraw',
  VIEW_DEPOSIT = 'view_deposit',
  VIEW_ALL_BILLING_ACCOUNTS = 'view_all_billing_accounts',
  DEACTIVATE_FEATURE_ITEM = 'deactivate_feature_item',
  COPY = 'copy',
  DELETE = 'delete',
  CREATE = 'create',
  MODIFY = 'modify',
  DOWNLOAD_ATTACHMENTS = 'download_attachments',
  CAMPAIGNS_SIGN_IN = 'campaigns_sign_in',
  CLEAR_QUEUE = 'clear_queue',
  CLEAR_LEADS_PAYMENT_INFO_FROM_FILE = 'clear_leads_payment_info_from_file',
  ENABLE_PAYMENT_INFO = 'enable_payment_information_info',
  FORCE_UPDATE_CAMPAIGN = 'force_update_campaign',
}

export interface AccessControlProps {
  userPermissions: Array<UserPermissions> | null;
  allowedPermissions: Array<UserPermissions>;
}

export function checkPermissions(
  userPermissions: Array<UserPermissions>,
  allowedPermissions: Array<UserPermissions>
): boolean {
  return allowedPermissions.every((allowedPermission) =>
    userPermissions.some((userPermission) => userPermission === allowedPermission)
  );
}

function AccessControl({userPermissions, allowedPermissions, children}: PropsWithChildren<AccessControlProps>) {
  const permitted = checkPermissions(userPermissions || [], allowedPermissions);
  return permitted ? (children as ReactElement<any>) : null;
}

export default AccessControl;
