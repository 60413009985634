import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import {Box} from '@mui/system';
import {DataGridPro, GridCellParams, GridRowModel} from '@mui/x-data-grid-pro';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {FeatureName} from '../../../paths';
import PageHeadline from '../../components/PageHeadline';
import GridRowCustom from '../../components/shared/GridRowCustom';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import {EventItem, EventOrder} from '../../model/Event';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import OrderDetailDialog from './dialogs/OrderDetailDialog';

function EventOrdersViewPage() {
  const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState<boolean>(false);
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [orderId, setOrderId] = useState<string | null>(null);
  const {useAxiosOutletOrderAPI: useAxiosEventAPI} = useAxiosContext();
  const {useAxiosAccountsAPI} = useAxiosContext();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.BILLING_REPORTING);
  const {id} = useParams();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const [{data: entityData, loading: isGetEntityLoading, error: getEntityError}, getEntityData] = useAxiosEventAPI<
    ResponseListWrapper<EventOrder>
  >(
    {
      url: `/outlet-orders`,
      method: 'get',
      params: {outlet_id: id},
    },
    {manual: false}
  );
  const [{data: orderDetail, loading: isOrderDetail, error: getOrderDetailError}, getOrderDetail] =
    useAxiosEventAPI<EventOrder>(
      {
        method: 'get',
      },
      {manual: true}
    );
  const [{data: patchOrderDetailData, loading: isPatchOrderDetail, error: patchOrderDetailError}, patchOrderDetail] =
    useAxiosEventAPI<EventOrder>(
      {
        method: 'patch',
      },
      {manual: true}
    );

  function handleActionsMenuIconClick(event: React.MouseEvent<HTMLElement>, cell: GridCellParams): void {
    const {row}: GridRowModel = cell;
    setIsOrderDetailsOpen(true);
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <PageStickyHeader>
        <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
          <Grid item xs={6}>
            <PageHeadline>{t('event-order.header')}</PageHeadline>
          </Grid>
          <Grid item xs={6}>
            <PageTopActions></PageTopActions>
          </Grid>
        </Grid>
      </PageStickyHeader>
      <Box sx={{gap: 2, p: 1, m: 2, height: '500px'}}>
        {entityData && (
          <DataGridPro
            // components={{
            //   Row: GridRowCustom,
            // }}
            columns={['view', 'outlet_order_id', 'status', 'total_items', 'total_price', 'lead_id', 'created_at'].map(
              (res, index) => {
                if (index === 0) {
                  return {
                    field: res,
                    headerName: res,
                    id: index,
                    renderCell: (params: GridCellParams) => {
                      return (
                        <>
                          <IconButton
                            id="campaign-list-more-actions-btn"
                            aria-haspopup="true"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              getOrderDetail({url: `outlet-orders/${params.row.outlet_order_id}`}).then((res) => {
                                setOrderStatus(res.data.status);
                                setOrderId(res.data.outlet_order_id);
                              });
                              handleActionsMenuIconClick(event, params);
                            }}
                            size="large"
                          >
                            <Visibility />
                          </IconButton>
                        </>
                      );
                    },
                  };
                }
                return {field: res, headerName: res.replaceAll('_', ' '), id: index};
              }
            )}
            rows={entityData.results.map((order, index) => {
              console.log(order);
              return {...order, id: index};
            })}
            pageSize={2}
          />
        )}
        {orderId && (
          <OrderDetailDialog
            orderId={orderId}
            onClose={() => {
              setIsOrderDetailsOpen(!isOrderDetailsOpen);
              setOrderStatus('');
            }}
            isOpen={isOrderDetailsOpen}
            getEntityListData={getEntityData}
          />
        )}
      </Box>
    </Box>
  );
}

export default EventOrdersViewPage;
