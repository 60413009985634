import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {
  Container,
  Button,
  DialogActions,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {GridRowModel} from '@mui/x-data-grid-pro';
import {useAxiosContext} from '../../../context/AxiosContext';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import {useParams} from 'react-router';
import {FeatureName} from '../../../../paths';
import {EventOrder} from '../../../model/Event';
import {useLoading} from '../../../context/LoadingContext';
import {ResponseListWrapper} from '../../../services/model/ResponseListWrapper';
import {AxiosPromise} from 'axios';
interface OrderDetailDialogProps {
  orderId: string;
  onClose: () => void;
  isOpen: boolean;
  getEntityListData: () => AxiosPromise<ResponseListWrapper<EventOrder>>;
}

interface DisplayedOrderDetails {
  leadId: string;
  outletId: string;
  outletOrderId: string;
  status: string;
  totalItems: number;
  totalPrice: string;
}
export default function OrderDetailDialog({orderId, onClose, isOpen, getEntityListData}: OrderDetailDialogProps) {
  const {featureId} = useParams();
  const audioSupportedFeatures = new Set([FeatureName.AI_CALL_LOG.toString()]);
  const {useAxiosOutletOrderAPI: useAxiosEventAPI} = useAxiosContext();
  const [parsedOderDetail, setParsedOrderDetail] = useState<DisplayedOrderDetails>();
  const [orderStatus, setOrderStatus] = useState<string>('');
  const {id} = useParams();
  const {setLoading} = useLoading();
  const [{data: orderDetail, loading: isOrderDetail, error: getOrderDetailError}, getOrderDetail] =
    useAxiosEventAPI<EventOrder>(
      {
        url: `outlet-orders/${orderId}`,
        method: 'get',
      },
      {manual: false}
    );

  const [
    {data: patchOrderDetailData, loading: isPatchOrderDetailLoading, error: patchOrderDetailError},
    patchOrderDetail,
  ] = useAxiosEventAPI<EventOrder>(
    {
      method: 'patch',
    },
    {manual: true}
  );
  useEffect(() => {
    if (orderDetail) {
      setOrderStatus(orderDetail.status);
    }
  }, [orderDetail]);
  const {t} = useTranslation();
  const colsToDisplay = {
    lead_id: 'lead_id',
    outlet_id: 'event id',
    outlet_order_id: 'order id',
    status: 'status',
    total_items: 'total items',
    total_price: 'total price',
  };

  return (
    <>
      <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={'md'}>
        <DialogTitle>
          <Typography variant="h4">Overview</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{padding: 0}}>
          <Grid container>
            {orderDetail &&
              Object.entries(colsToDisplay).map(([column, label], index) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={index % 2 ? 'overview-dialog-row-odd' : 'overview-dialog-row-even'}
                      sx={{
                        paddingInline: '25px',
                        paddingBlock: '14px',
                      }}
                    >
                      <Typography component="span" sx={{fontWeight: '500'}}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={9}
                      sx={{paddingInline: '25px', paddingBlock: '14px'}}
                      className={index % 2 ? 'overview-dialog-row-odd-light' : 'overview-dialog-row-even-light'}
                    >
                      {column === 'status' && isPatchOrderDetailLoading ? (
                        <Box height={40}>
                          <CircularProgress size={30} />
                        </Box>
                      ) : (
                        column === 'status' && (
                          <FormControl sx={{minWidth: '130px', height: '40px'}}>
                            <InputLabel id="status-label">{t('events.status-update')}</InputLabel>
                            <Select
                              labelId="status-label"
                              id="status"
                              name="status"
                              value={orderStatus}
                              onChange={(e) => setOrderStatus(e.target.value)}
                              label="Status"
                            >
                              <MenuItem value={'completed'}>{t('events.status.completed')}</MenuItem>
                              <MenuItem value={'cancelled'}>{t('events.status.cancelled')}</MenuItem>
                              <MenuItem value={'refunded'}>{t('events.status.refunded')}</MenuItem>
                              <MenuItem value={'pending'}>{t('events.status.pending')}</MenuItem>
                              <MenuItem value={'verified'}>{t('events.status.verified')}</MenuItem>
                              <MenuItem value={'failed'}>{t('events.status.failed')}</MenuItem>
                            </Select>
                          </FormControl>
                        )
                      )}

                      <Typography sx={{whiteSpace: 'pre-wrap'}}>
                        {column !== 'status' && orderDetail[column]}
                      </Typography>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          {orderDetail?.attachments && (
            <ImageList sx={{width: 500, padding: '25px'}} cols={3} rowHeight={164}>
              {orderDetail.attachments.map((attachment) => (
                <a href={attachment} target="_blank">
                  <ImageListItem key={attachment}>
                    <img srcSet={attachment} src={attachment} alt={'attachment'} loading="lazy" />
                  </ImageListItem>
                </a>
              ))}
            </ImageList>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              patchOrderDetail({
                url: `outlet-orders/${orderId}`,
                data: {
                  status: orderStatus,
                },
              }).then(() => {
                getEntityListData();
                getOrderDetail();
              });
            }}
          >
            {t('billing.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
