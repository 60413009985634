import {Box, Dialog, DialogContent, DialogTitle, Skeleton, Typography} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import {useTranslation} from 'react-i18next';
import GridRowCustom from '../../components/shared/GridRowCustom';
import {useAxiosContext} from '../../context/AxiosContext';
import {Expense} from '../../model/BillingReporting';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {getAPIFormattedDateFromExpense} from './BillingUtils';

interface ReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  reportDataSource: Expense;
  accountId: string;
  service: string;
}

interface ReportingData {
  created_at: string;
  created_by: string;
  fee_per_unit: string;
  min_billable_units: string;
  operation_id: string;
  resource_id: string;
  resource_status: string;
  resource_type: string;
  spent: string;
  transaction_id: string;
  unit: string;
  units: string;
  billed_units: string;
}

export default function ReportDialog({isOpen, onClose, reportDataSource, accountId, service}: ReportDialogProps) {
  const startDate = getAPIFormattedDateFromExpense(reportDataSource);
  let endDate: Date;
  let groupBy: string;
  if (reportDataSource.day) {
    endDate = new Date(startDate.getTime());
    endDate.setHours(23, 59, 59, 999);
    groupBy = 'days';
  } else if (reportDataSource.month) {
    endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    endDate.setHours(23, 59, 59, 999);
    groupBy = 'months';
  } else {
    throw Error('date not supported');
  }
  const {t} = useTranslation();
  const columns: GridColDef[] = [
    {field: 'rvm_id', headerName: 'rvm id', width: 150, editable: false},
    {field: 'status', headerName: 'status', width: 150, editable: false},
    {field: 'created_at', headerName: 'created at', width: 150, editable: false},
    {field: 'bill rate', headerName: 'bill rate', width: 150, editable: false},
    {field: 'billeg', headerName: 'billeg', width: 150, editable: false},
  ];
  const isLoading = false;
  const {useAxiosAccountsAPI} = useAxiosContext();
  const [{data: entityData, loading: isGetEntityLoading, error: getEntityError}, getEntityData] = useAxiosAccountsAPI<
    ResponseListWrapper<ReportingData>
  >({
    url: `/reporting/accounts/${accountId}/services/${service}/expenses`,
    method: 'GET',
    params: {
      group_by: groupBy,
      filter_by: 'range',
      from_timestamp: startDate.toISOString(),
      to_timestamp: endDate.toISOString(),
    },
  });
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{display: 'flex', gap: '10px'}}>
        <GridToolbarExport
          csvOptions={{fileName: `Report_${service}_${startDate.toISOString()}_${endDate.toISOString()}`}}
          printOptions={{
            disableToolbarButton: true,
          }}
        />
        <GridToolbarFilterButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
        <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
      </GridToolbarContainer>
    );
  }
  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        <Typography fontSize={25} fontWeight={'medium'}>
          {t('billing.dialog.header')}: {t(`billing.${service}`.replaceAll('_', '-'))}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{height: 400, width: '100%'}}>
          {isLoading ? (
            <Skeleton variant="rectangular" sx={{height: '100%'}} />
          ) : (
            entityData &&
            entityData.results[0] && (
              <DataGridPro
                components={{
                  Toolbar: CustomToolbar,
                  Row: GridRowCustom,
                }}
                columns={[
                  'created_at',
                  'created_by',
                  'fee_per_unit',
                  'min_billable_units',
                  'operation_id',
                  'resource_id',
                  'resource_status',
                  'resource_type',
                  'spent',
                  'transaction_id',
                  'unit',
                  'units',
                  'billed_units',
                ].map((res, index) => {
                  return {field: res, headerName: res.replaceAll('_', ' '), id: index};
                })}
                rows={entityData.results.map((expense, index) => {
                  return {...expense, id: index};
                })}
                pageSize={2}
              />
            )
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
