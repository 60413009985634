import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {useEffect, useState} from 'react';
import PageHeadline from '../../components/PageHeadline';
import {MakeOptional} from '@mui/x-charts/internals';
import {BarChart, BarSeriesType} from '@mui/x-charts';
import {DatePicker, DateRangePicker} from '@mui/x-date-pickers-pro';
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateRange} from '@mui/lab';
import ServiceCard from './ServiceCard';
import ReportDialog from './ReportDialog';
import {PageTopActions} from '../PageTopActions';
import {PageStickyHeader} from '../PageStickyHeader';
import {useTranslation} from 'react-i18next';
import {useAxiosContext} from '../../context/AxiosContext';
import UserService from '../../services/UserService';
import {useLoading} from '../../context/LoadingContext';
import {v4 as uuidv4} from 'uuid';
import 'uuid';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {FeatureName} from '../../../paths';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {AccountReport, BillingAccount, Expense, FormData, ServiceReport} from '../../model/BillingReporting';
import {formatForBarChart, getFormattedXAxisDates} from './BillingUtils';
import {AccountBalance} from '../../components/SideNavBalance';

export default function BillingPage() {
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  const aWeekAgo = new Date();
  aWeekAgo.setDate(today.getDate() - 7);
  aWeekAgo.setHours(0, 0, 0, 0);

  const [reportData, setReportData] = useState<MakeOptional<BarSeriesType, 'type'>[]>();
  const [formData, setFormData] = useState<FormData>({
    groupBy: 'days',
    filterBy: 'range',
    range: [aWeekAgo, today],
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isWithdrawOpen, setIsWithdrawOpen] = useState<boolean>(false);
  const [isDepositOpen, setIsDepositOpen] = useState<boolean>(false);
  const [reportDataSource, setReportDialogData] = useState<Expense>();
  const [userAccounts, setUserAccounts] = useState<BillingAccount[]>();
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [xAxisDates, setXAxisDates] = useState<string[]>();
  const [reportingDialogService, setReportingDialogService] = useState<string>();
  const [withdrawalAmount, setWithdrawalAmount] = useState<number>(0);
  const [depositAmount, setDepositAmount] = useState<number>(0);
  const [billingAccountToDisplay, setBillingAccountToDisplay] = useState<BillingAccount>();
  const [displayBalance, setDisplayBalance] = useState<string>();
  const {t} = useTranslation();
  const {useAxiosAccountsAPI} = useAxiosContext();
  const {setLoading} = useLoading();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.BILLING_REPORTING);

  const [{data: entityData, loading: isGetEntityLoading}, getEntityData] = useAxiosAccountsAPI<AccountReport>(
    {url: `/reporting/accounts/${formData.billing_account_id}/expenses`, method: 'GET'},
    {manual: true, autoCancel: false}
  );

  const [, postTransaction] = useAxiosAccountsAPI<any>({url: `/admin/transactions`, method: 'POST'}, {manual: true});

  const [{data: billingAccountsData}, getBillingAccountsData] = useAxiosAccountsAPI<
    ResponseListWrapper<BillingAccount>
  >({url: `/admin/accounts`, method: 'GET'}, {manual: true, autoCancel: false});

  const [{data: accountData, loading: accountDataLoading, error: getAccountDataError}, getAccountData] =
    useAxiosAccountsAPI<AccountBalance>(
      {
        url: `/reporting/accounts/${billingAccountToDisplay?.account_id}/balance`,
        method: 'GET',
      },
      {manual: true}
    );

  useEffect(() => {
    setLoading(isGetEntityLoading, 'BillingPage');
  }, [isGetEntityLoading]);

  function canViewAllBillingAccounts(): boolean {
    return userPermissions?.includes(UserPermissions.VIEW_ALL_BILLING_ACCOUNTS) || false;
  }

  useEffect(() => {
    if (canViewAllBillingAccounts()) {
      getBillingAccountsData()
        .then((res) => {
          if (!formData.billing_account_id) {
            const newFormData = {...formData, billing_account_id: res.data.results[0].account_id};
            setFormData(newFormData);
            onFormSubmit(newFormData as FormData);
          }
        })
        .catch(() => {
          NotificationService.getInstance().sendNotification(
            t('billing.error-fetching-account'),
            NotificationType.ERROR
          );
        });
    }
  }, [userPermissions]);

  useEffect(() => {
    const subscription = UserService.getInstance()
      .get()
      .subscribe((user) => {
        if (user?.accounts) {
          setUserAccounts(user.accounts);
          setBillingAccountToDisplay(user.accounts[0]);
          const newFormData = {...formData, billing_account_id: user.accounts[0].account_id};
          setFormData(newFormData);
          onFormSubmit(newFormData);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if ((billingAccountsData || userAccounts) && formData.billing_account_id) {
      let accountFilter;
      if (billingAccountsData) {
        accountFilter = billingAccountsData?.results.filter(
          (account) => account.account_id === formData.billing_account_id
        );
      } else if (userAccounts) {
        accountFilter = userAccounts?.filter((account) => account.account_id === formData.billing_account_id);
      }
      if (accountFilter?.[0]) {
        setBillingAccountToDisplay(accountFilter[0]);
      }
      onFormSubmit(formData);
    }
  }, [billingAccountsData, formData.billing_account_id, userAccounts]);

  useEffect(() => {
    if (billingAccountToDisplay) {
      getAccountData();
    }
  }, [billingAccountToDisplay]);

  useEffect(() => {
    if (accountData) {
      setDisplayBalance(accountData.balance);
    }
  }, [accountData]);

  const onFormSubmit = (formData: FormData) => {
    if (!formData.billing_account_id && !canViewAllBillingAccounts()) {
      NotificationService.getInstance().sendNotification(t('billing.error-no-account'), NotificationType.ERROR);
      return;
    } else if (!isFormValid || !formData.range?.[0] || !formData.range?.[1] || !formData.billing_account_id) {
      NotificationService.getInstance().sendNotification(t('billing.error-invalid-form'), NotificationType.ERROR);
      return;
    }

    const totalDaysRequested = (formData.range[1]?.getTime() - formData.range[0].getTime()) / (1000 * 60 * 60 * 24) + 1;
    const maxDaysSupported = 100;
    if (totalDaysRequested > maxDaysSupported && formData.groupBy === 'days') {
      NotificationService.getInstance().sendNotification(
        `${t('billing.error-days-amount')} ${maxDaysSupported} ${t('billing.days')}`,
        NotificationType.ERROR
      );
      return;
    }

    setXAxisDates(getFormattedXAxisDates(formData));
    getEntityData({
      url: `/reporting/accounts/${formData.billing_account_id}/expenses`,
      params: {
        group_by: formData.groupBy,
        filter_by: formData.filterBy,
        from_timestamp: formData.range[0]?.toISOString(),
        to_timestamp: formData.range[1]?.toISOString(),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setReportData(formatForBarChart(response.data, formData));
        }
      })
      .catch(() => {
        NotificationService.getInstance().sendNotification(t('billing.error-fetching-data'), NotificationType.ERROR);
      });
  };

  const handleOnChangeSelect = (e: SelectChangeEvent) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const createTransaction = (action: 'withdraw' | 'recharge') => {
    const spent = action === 'withdraw' ? withdrawalAmount : depositAmount;
    const body = {
      account_id: formData.billing_account_id,
      amount: action === 'withdraw' ? Math.abs(spent) * -1 : Math.abs(spent),
      idempotency_key: uuidv4(),
      service: action,
    };
    postTransaction({data: body})
      .then((res) => {
        if (res.status === 201) {
          NotificationService.getInstance().sendNotification(t('billing.success'), NotificationType.SUCCESS);
          getAccountData();
        }
      })
      .catch(() => {
        NotificationService.getInstance().sendNotification(
          t('billing.error-creating-transaction'),
          NotificationType.ERROR
        );
      });
  };

  const serviceCards =
    entityData &&
    reportData &&
    Object.entries(entityData).map(([serviceName, data]: [string, ServiceReport]) => (
      <ServiceCard
        service={serviceName}
        amount={Number(data.total_expenses)}
        billedUnits={Number(data.total_billed_units)}
        key={serviceName}
      />
    ));

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <PageStickyHeader>
        <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
          <Grid item xs={6}>
            <PageHeadline>{t('billing.header')}</PageHeadline>
          </Grid>
          <Grid item xs={6}>
            <PageTopActions></PageTopActions>
          </Grid>
        </Grid>
      </PageStickyHeader>
      <Dialog open={isWithdrawOpen} onClose={() => setIsWithdrawOpen(!isWithdrawOpen)} fullWidth>
        <DialogTitle>
          <Typography fontSize={'1.5rem'} fontWeight={400}>
            {t('billing.withdraw-from')} {billingAccountToDisplay?.name}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{t('billing.transaction-description')}</Typography>
          <TextField
            sx={{marginTop: '30px'}}
            onChange={(e) => setWithdrawalAmount(Number(e.target.value))}
            type={'number'}
            label={t('billing.amount')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => createTransaction('withdraw')}>{t('billing.submit')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDepositOpen} onClose={() => setIsDepositOpen(!isDepositOpen)} fullWidth>
        <DialogTitle>
          <Typography fontSize={'1.5rem'} fontWeight={400}>
            {t('billing.deposit-to')} {billingAccountToDisplay?.name}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t('billing.transaction-description')} {billingAccountToDisplay?.name}
          </Typography>
          <TextField
            sx={{marginTop: '30px'}}
            type={'number'}
            label={t('billing.amount')}
            onChange={(e) => setDepositAmount(Number(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => createTransaction('recharge')}>{t('billing.submit')}</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{display: 'flex', flexDirection: 'row', gap: 2, p: 1, m: 2}}>
        {formData.billing_account_id && (
          <FormControl sx={{minWidth: 150}}>
            <InputLabel id="billing-account-label">{t('billing.billling-account')}</InputLabel>
            <Select
              labelId="billing-account-label"
              id="billing-account"
              name="billing_account_id"
              value={formData.billing_account_id}
              label={t('billing.billling-account')}
              onChange={handleOnChangeSelect}
              required={true}
            >
              {canViewAllBillingAccounts()
                ? billingAccountsData?.results.map((billingAccount) => {
                    return (
                      <MenuItem value={billingAccount.account_id} key={billingAccount.account_id}>
                        {billingAccount.name}
                      </MenuItem>
                    );
                  })
                : userAccounts?.map((accountId) => {
                    return (
                      <MenuItem value={accountId.account_id} key={accountId.account_id}>
                        {accountId.name}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        )}
        <TextField
          sx={{width: '150px'}}
          label="Account Balance"
          value={!accountDataLoading && displayBalance ? displayBalance : ''}
          InputProps={{readOnly: true}}
        />
        <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_WITHDRAW]}>
          <Button onClick={() => setIsWithdrawOpen(!isWithdrawOpen)}>{t('billing.withdraw')}</Button>
        </AccessControl>
        <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_DEPOSIT]}>
          <Button onClick={() => setIsDepositOpen(!isDepositOpen)}>{t('billing.deposit')}</Button>
        </AccessControl>
      </Box>

      <Box m={3}>
        <Typography variant="h2">
          {t('billing.summary')}: {billingAccountToDisplay?.name}
        </Typography>
        <Typography sx={{marginTop: '10px'}}>{t('billing.utc-warning')}</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', gap: 2, p: 1, m: 2}}>
        <FormControl sx={{minWidth: 100}}>
          <InputLabel id="group-by-label">{t('billing.group-by')}</InputLabel>
          <Select
            labelId="group-by-label"
            id="group-by"
            name="groupBy"
            value={formData.groupBy}
            onChange={handleOnChangeSelect}
            label="Group By"
          >
            <MenuItem value={'days'}>{t('billing.days-caps')}</MenuItem>
            <MenuItem value={'months'}>{t('billing.months')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{minWidth: 100}}>
          <InputLabel id="filter-by-label">{t('billing.filter-by')}</InputLabel>
          <Select
            labelId="filter-by-label"
            id="filter-by"
            name="filterBy"
            value={formData.filterBy}
            label="Filter By"
            onChange={handleOnChangeSelect}
          >
            <MenuItem value={'range'}>{t('billing.range')}</MenuItem>
            {formData.groupBy === 'years' && <MenuItem value={'year'}>{t('billing.year')}</MenuItem>}
          </Select>
        </FormControl>
        {formData.filterBy === 'year' && (
          <FormControl sx={{minWidth: 100}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={['year']}
                label={t('billing.year')}
                minDate={new Date(2024, 1, 1)}
                maxDate={new Date()}
                value={formData.year}
                onChange={(newValue) => {
                  if (newValue) {
                    setFormData({...formData, year: newValue});
                  }
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </FormControl>
        )}
        {formData.filterBy === 'range' && (
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                disableFuture
                value={formData.range || [null, null]}
                onError={(e) => setIsFormValid(e.every((val) => val === null))}
                onChange={(range: DateRange<Date>) => {
                  if (range[0]) {
                    range[0].setHours(0, 0, 0, 0);
                  }
                  if (range[1]) {
                    range[1].setHours(23, 59, 59, 999);
                  }
                  setFormData({...formData, range: range});
                }}
                calendars={1}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField sx={{width: '130px'}} {...{...startProps, label: t('billing.from')}} />
                    <Box sx={{mx: 2}}> - </Box>
                    <TextField sx={{width: '130px'}} {...{...endProps, label: t('billing.to')}} />
                  </>
                )}
              />
            </LocalizationProvider>
          </FormControl>
        )}
        <Button onClick={() => onFormSubmit(formData)}>{t('billing.submit')}</Button>
      </Box>
      <>{serviceCards}</>
      {reportData && entityData && (
        <BarChart
          series={reportData}
          height={439}
          width={953}
          grid={{horizontal: true}}
          xAxis={[
            {
              data: xAxisDates,
              scaleType: 'band',
              label: t('billing.dates'),
              tickPlacement: 'middle',
              tickLabelPlacement: 'middle',
            },
          ]}
          margin={{top: 90, bottom: 70, left: 40, right: 10}}
          yAxis={[{label: t('billing.amount-spent')}]}
          onItemClick={(e, item) => {
            if (formData.groupBy === 'years') return;
            const serviceIndex = parseInt(item.seriesId.toString().slice(-1));
            const serviceName = reportData[serviceIndex].label;
            const serviceData = reportData[serviceIndex].data;

            if (serviceName && serviceData) {
              const toRemove = serviceData.filter((val, index) => Number(val) === 0 && index < item.dataIndex).length;
              const markAssociatedData = entityData[serviceName.toString()].expenses[item.dataIndex - toRemove];
              setReportDialogData(markAssociatedData as Expense);
              setReportingDialogService(serviceName.toString());
              setIsOpen(true);
            }
          }}
          sx={{p: 1}}
        />
      )}
      {reportDataSource && reportingDialogService && formData.billing_account_id && (
        <ReportDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          reportDataSource={reportDataSource}
          accountId={formData.billing_account_id}
          service={reportingDialogService}
        />
      )}
    </Box>
  );
}
